import { CoachsSkill } from "@/interfaces/coachsSkill.interface";
import { defineStore } from "pinia";

export const useSkillsStore = defineStore("skills", {
  state: () => ({
    skills: [] as CoachsSkill[]
  }),
  getters: {
    getSkills(state: any): CoachsSkill[] {
      return state.skills;
    }
  },
  actions: {
    setSkills(skills: CoachsSkill[]): void {
      this.skills = skills;
    },
    addSkill(skill: CoachsSkill): void {
      this.skills.push(skill);
    },
    addSkills(skills: CoachsSkill[]): void {
      this.skills.push(...skills);
    },
    updateSkill(skill: CoachsSkill): void {
      const index = this.skills.findIndex((s: CoachsSkill) => s.id === skill.id);
      this.skills[index] = skill;
    },
    removeSkill(id: number): void {
      this.skills = this.skills.filter((skill: CoachsSkill) => skill.id !== id);
    }
  }
});

export default useSkillsStore;
